<script>
	import { insightAttributes } from '$lib/attributes.js';
	export let attribute = '';
	export let includeText = undefined;
	export let includeIcon = true;
	export let textOverride = '';
	export let glowing = true;
	export let forceUppercase = true;
	export let color;
	export let size = undefined; // = [rem, rem]

	let attributes = insightAttributes;
	$: attributeDetails =
		attributes[
			(!insightAttributes[attribute] && /\s|_/.test(attribute)
				? attribute?.split(/\s|_/)[0]
				: attribute
			).toLowerCase()
		];
	$: color = color || attributeDetails?.color;
</script>

{#if includeIcon && (!size || size[1])}
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class={'h-5 w-5'}
		style="{glowing ? 'filter: drop-shadow(0px 0px 5px' + color + ');' : ''}color:{color};{size
			? `width:${size?.[0]}rem;height:${size[1]}rem;`
			: ''}"
		viewBox="0 0 20 20"
		fill={attribute === 'brainpower' ? 'transparent' : color}
		stroke={attribute !== 'brainpower' ? 'transparent' : color}
	>
		{@html attributeDetails?.icon}
	</svg>
{/if}
{#if includeText}
	<div
		class="{forceUppercase ? 'uppercase' : ''} text-sm font-semibold w-max"
		style="color:{color};{glowing ? 'filter: drop-shadow(0px 0px 5px' + color + ');' : ''}{size
			? `font-size:${size?.[0] / 1.8}rem;`
			: ''}"
	>
		{textOverride ||
			[
				(attributeDetails?.display || attribute)
					.replace(new RegExp(`_|${'sleep '}`, 'g'), ' ')
					.split(attribute.includes('sleep') ? ' ' : false)
			]
				.flat()
				.pop()}
	</div>
{/if}
